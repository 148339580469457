<template>
  <order-view :id="id"></order-view>
</template>

<script>
import OrderView from "@/components/views/orders/OrderView";
import router from "@/router";

export default {
  name: "OrderPage",
  components: {
    OrderView,
  },
  methods: {
    loadOrder() {

    }

  },
  computed: {
    id: function () {
      return this.$router.currentRoute.params.id;
    }
  }
}
</script>

<style scoped>

</style>